import Link from "components/link";
import { ShowMoreText } from "components/show-more-text";
import { ResolvedUrl } from "constants/types";
import { useLabels } from "helpers/hooks";
import { useSlugPath } from "helpers/routing";
import { FC, useState } from "react";
import { Dp, Pc } from "../../../types";
import { Heading } from "../../heading";
import { List, ListItemWithShape } from "../../lists";
import {
  DetailedProcessBody,
  DetailedProcessContainer,
  DetailedProcessSidebar,
} from "./base";
import { DetailedProcessHeader } from "./header";

export const DetailedProcessList: FC<{
  dp_list: ResolvedUrl<Dp>[];
  pc: Pc[];
}> = ({ dp_list, pc }) => {
  let touchDevice = false;
  if (typeof document !== "undefined") {
    touchDevice = "ontouchstart" in document.documentElement;
  }

  const path = useSlugPath();
  const [processDetailsBtn] = useLabels(["ui-25", "Process details"]);
  const [activeHover, setActiveHover] = useState(null);
  const [active, setActive] = useState(null);
  const handleClick = (id: number = 0) => {
    const index = id;
    if (index !== active) {
      setActive(index);
      setActiveHover(index);
    }
  };

  const links = dp_list
    ? dp_list.map((dp, i) => {
        // first one is overview, so index should start at second element
        const realIndex = i - 1;

        const key_visual = dp?.content_overview?.[0];
        let action_area = [];
        try {
          if (dp.action_area) {
            action_area = JSON.parse(dp.action_area);
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }

        if (key_visual) {
          key_visual.digital_asset_transformation =
            key_visual?.digital_asset_transformation?.replace(
              /(pg_name:illustration;)|(pg_name:captions[a-z_-]+)/gm,
              "pg_name:",
            );
        }

        return {
          id: dp.id,
          label: dp.label,
          keyvisual: key_visual,
          onClick: () => handleClick(realIndex),
          onHover: touchDevice ? () => {} : () => setActiveHover(realIndex),
          url: dp._url,
          coords: action_area.map((area) => ({
            w: area[0],
            h: area[1],
            x: area[2],
            y: area[3],
            circle: area[4],
          })),
        };
      })
    : [];

  return (
    <>
      <DetailedProcessHeader
        media={pc?.[0]?.content_overview?.[0]}
        links={links}
        active={active}
        activeHover={activeHover}
        setActiveHover={setActiveHover}
        id={pc?.[0]?.id}
      />
      <DetailedProcessContainer className="mt-3 mt-lg-4 mt-xl-5">
        <DetailedProcessSidebar>
          <p className="h5 bold">{processDetailsBtn.label}</p>
          <List>
            {dp_list.map((dp, i) => (
              <li key={dp.slug}>
                <Link
                  href={dp._url + "#" + dp.id}
                  prefetch={false}
                  legacyBehavior
                >
                  <a
                    className={`${
                      (active !== null && i === active + 1) ||
                      (activeHover !== null && i === activeHover + 1)
                        ? "active"
                        : ""
                    }`}
                    {...(!touchDevice
                      ? {
                          onMouseEnter: () => setActiveHover(i - 1),
                          onMouseLeave: () => setActiveHover(active),
                        }
                      : {})}
                  >
                    <ListItemWithShape>{dp.label}</ListItemWithShape>
                  </a>
                </Link>
              </li>
            ))}
          </List>
        </DetailedProcessSidebar>
        <DetailedProcessBody className="col-xl-8 mt-3 mt-lg-0 px-2 px-lg-3">
          <Heading title={pc?.[0]?.title} level="h1" stylingLevel="h3" />
          <div>
            <ShowMoreText content={pc?.[0]?.excerpt} />
          </div>
        </DetailedProcessBody>
      </DetailedProcessContainer>
    </>
  );
};
