import { Container } from "components/container";
import HeroSlider from "components/slider/hero-slider";
import { getEdgeSettings } from "components/wrapper/components";
import { HeroSliderProps, WrapperComponentProps } from "constants/types";
import { FC } from "react";

interface SlidesWrapperProps extends WrapperComponentProps, HeroSliderProps {}

const SliderWrapper: FC<SlidesWrapperProps> = (props) => (
  <Container
    edge={getEdgeSettings(props)}
    marginTop={props.margin_top}
    marginBottom={props.margin_bottom}
    background={props.background_color}
    layout={props.header_layout}
    fluid={true}
    id={`component__slider-${props.index}`}
    className="section component__slider"
  >
    <HeroSlider slides={props.slides} />
  </Container>
);

export default SliderWrapper;
