import { Button } from "components/button";
import { useLabels } from "helpers/hooks";
import { makeParagraph, sanitize } from "helpers/text-processing";
import { FC, useEffect, useState } from "react";

export const ShowMoreText: FC<{ className?: string; content: string }> = ({
  content,
  className,
}) => {
  const [showMoreButtonAction] = useLabels(["ui-107", "show more"]);

  const parsedContent = makeParagraph(content).replace(/<p>\s*<\/p>/gi, "");

  const regex = /(<button class="expand-hidden-content">)(.*)(<\/button>)(.*)/;
  const buttonRegex = /(?=<button class="expand-hidden-content">).*\/button>/;

  const hiddenPartReg = regex.exec(parsedContent);
  const hiddenPart = hiddenPartReg ? hiddenPartReg?.[4] : "";

  const button = buttonRegex.exec(parsedContent);
  const replacedContent = parsedContent
    .replace(hiddenPart, "")
    .replace(button ? button[0] : "", "");

  const [componentContent, setComponentContent] = useState(replacedContent);
  const [buttonVisibility, setButtonVisibility] = useState(true);

  useEffect(() => {
    setComponentContent(replacedContent);
  }, [replacedContent]);

  return (
    <>
      <div
        className={className}
        dangerouslySetInnerHTML={{
          __html: sanitize(componentContent),
        }}
      />
      {buttonVisibility && hiddenPart ? (
        <Button
          variant={"primary-outline"}
          icon="plus"
          label={showMoreButtonAction.label}
          onClick={() => {
            setComponentContent(componentContent + hiddenPart);
            setButtonVisibility(false);
          }}
          className="mb-2"
        />
      ) : null}
    </>
  );
};
