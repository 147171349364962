import classNames from "classnames";
import React, { FC } from "react";

export const DetailedProcessContainer: FC<
  React.HTMLAttributes<HTMLDivElement>
> = ({ children, className }) => (
  <div className={classNames("detailed-process-list", className)}>
    <div className="container">
      <div className="d-block d-lg-flex">{children}</div>
    </div>
  </div>
);

export const DetailedProcessSidebar: FC<
  React.HTMLAttributes<HTMLDivElement>
> = ({ children }) => (
  <div className="dp-nav col-12 col-lg-6 col-xl-4">{children}</div>
);

export const DetailedProcessBody: FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
}) => (
  <div
    className={classNames(
      "dp-content",
      "col-12",
      "col-lg-6",
      "col-xl-8",
      className,
    )}
  >
    {children}
  </div>
);
