import classNames from "classnames";
import { Button } from "components/button";
import { Card } from "components/card";
import { Cloudinary } from "components/cloudinary";
import { Container } from "components/container";
import { ContentSet } from "components/contentset";
import { QuickFilter } from "components/contentset/quick-filter";
import Link from "components/link";
import { DetailedProcessList } from "components/page/detailed-process";
import { Shape } from "components/shape";
import { Slider } from "components/slider";
import { getEdgeSettings } from "components/wrapper/components";
import DefaultComponent from "components/wrapper/components/default";
import HeadingWrapper from "components/wrapper/components/heading";
import SliderWrapper from "components/wrapper/components/slider";
import { entitiesConfig } from "config/entity";
import {
  Entity,
  KeyVisual,
  WithResolvedUrl,
  WrapperComponentProps,
} from "constants/types";
import { formatEventDate } from "helpers/date";
import { useLabels } from "helpers/hooks";
import { useLocale } from "helpers/locale";
import { FC, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { Dp, Event } from "types";

const ContentSetWrapper: FC<WrapperComponentProps> = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 576 });
  const { locale, language, country } = useLocale();

  const [entityConfig, setEntityConfig] = useState(
    entitiesConfig[
      props?.content_set?.[0]?.contentset?.entity_type ?? "_default"
    ] || entitiesConfig._default,
  );
  useEffect(() => {
    setEntityConfig(
      entitiesConfig[
        props?.content_set?.[0]?.contentset?.entity_type ?? "_default"
      ] || entitiesConfig._default,
    );
  }, [props?.content_set]);

  const {
    preview_image_framing,
    preview_image_framing_cl_param,
    preview_image_background,
    preview_image_container_border,
  } = entityConfig;

  const [callToActionBtn, onlyEnglishBtn, canceledBtn, postponedBtn] =
    useLabels(
      [entityConfig?.call_to_action_button._default, "Details"],
      ["ui-274", '"(English)"'],
      ["ui-813", "Canceled"],
      ["ui-814", "Postponed"],
    );

  if (props.content_set && props.content_set[0]) {
    const layout = props.content_set?.[0]?.contentset?.layout ?? "list";
    // only render specific content set, if content_set option is given

    // if layout is image_area_map bail out of content_set default rendering and use a custom rendering
    // because we are not utilising any features of the content_set component at all
    if (layout === "image_area_map") {
      return (
        <Container
          edge={getEdgeSettings(props)}
          background={props.background_color}
          marginTop={props.margin_top}
          marginBottom={props.margin_bottom}
          layout={props.header_layout}
          fluid={true}
          id={`component__content-set-${props.index}`}
          className={`section detailprocess__header component__content-set`}
        >
          <DetailedProcessList
            dp_list={props.content_set?.[0]?.items as Dp[]}
            pc={Array.isArray(props.pc) ? props.pc : [props.pc]}
          />
        </Container>
      );
    }

    const isFinder = layout === "finder";
    if (layout === "slider") {
      const sliderType =
        props.content_set?.[0]?.contentset?.slider_type ?? "standard";
      if (sliderType === "fullsize") {
        return (
          <SliderWrapper
            index={props.index}
            blank={false}
            border={false}
            edge_border_bottom={false}
            edge_border_top={false}
            enabled={true}
            formmodal={false}
            level="h2"
            offset_border_bottom={false}
            offset_border_top={false}
            openlanguageswitcher={false}
            outline={false}
            small={false}
            styling_level="h2"
            type=""
            slides={props.content_set?.[0]?.items}
          />
        );
      }
      const items = props.content_set?.[0]?.items as WithResolvedUrl<Event>[];

      return (
        <Container
          edge={getEdgeSettings(props)}
          background={props.background_color}
          layout={props.header_layout}
          marginTop={props.margin_top}
          marginBottom={props.margin_bottom}
          fluid={isFinder}
          id={`component__content-set-${props.index}`}
          className={`${
            !isFinder ? "section " : "contentset-finder pt-3 "
          }component__content-set`}
          header={
            <HeadingWrapper
              {...props}
              alignment={isFinder ? "center" : null}
              border={!isFinder && props.header_layout !== "heading-left"}
              margin="mb-3"
            />
          }
        >
          {isMobile ? (
            <div className="sliderMobileFallback">
              {items.slice(0, 3).map((item, index) => {
                return (
                  <div
                    className="sliderMobileFallback__item my-3"
                    key={"key-" + index}
                  >
                    <div
                      style={{ color: "#808B94", fontSize: "14px" }}
                      className="mb-1"
                    >
                      {formatEventDate(
                        item.start_date as string,
                        item.end_date as string,
                        locale,
                      )}
                    </div>
                    <p style={{ color: "#455C6E" }} className="mb-1 h5">
                      <strong>{item.title}</strong>
                    </p>
                    <div style={{ fontSize: "12px" }} className="mb-2">
                      {item.location}
                    </div>
                    <Button
                      label={
                        callToActionBtn.short_title +
                        (item?._lang !== language &&
                        !entityConfig.ignore_different_lang
                          ? " (" + onlyEnglishBtn.label + ")"
                          : "")
                      }
                      locale={
                        item?._lang !== language &&
                        !entityConfig.ignore_different_lang
                          ? country + "_en"
                          : entityConfig.ignore_different_lang
                          ? country + "_" + language
                          : null
                      }
                      url={item._url}
                      variant={"small"}
                      blank={item._url?.includes("//")}
                    />
                    {index < 2 && <hr />}
                  </div>
                );
              })}
            </div>
          ) : (
            <Slider
              className="pl-0 pl-lg-0 event-slider"
              slidesPerView={2}
              slidesPerGroup={1}
              breakpoints={{
                768: { slidesPerView: 2 },
                992: { slidesPerView: 2 },
                1200: { slidesPerView: 4 },
              }}
              navigation="default"
            >
              {items.map((item) => {
                return (
                  <div style={{ minHeight: "180px" }} key={item.id}>
                    <Link
                      href={item._url}
                      prefetch={false}
                      target={item._url?.includes("//") ? "_blank" : undefined}
                    >
                      <div className="event-slider-image">
                        {item.images?.[0] && (
                          <Cloudinary
                            media={item.images[0]}
                            responsiveOrientation="height"
                          />
                        )}
                      </div>
                    </Link>

                    <div className="event-slider-date">
                      {item.canceled ? (
                        <>
                          <Shape variant="close-big" fill="#808B94" />
                          <div className="ml-1">{canceledBtn.label}</div>
                        </>
                      ) : item.postponed ? (
                        <>
                          <Shape variant="display" fill="#808B94" />
                          <div className="ml-1">{postponedBtn.label}</div>
                        </>
                      ) : (
                        formatEventDate(
                          item.start_date as string,
                          item.end_date as string,
                          locale,
                        )
                      )}
                    </div>
                    <Link
                      href={item._url}
                      prefetch={false}
                      target={item._url?.includes("//") ? "_blank" : undefined}
                    >
                      <p
                        className="event-slider-title glbl-hover h6"
                        title={item.title}
                      >
                        {item.title}
                      </p>
                    </Link>
                    <div className="event-slider-location">{item.location}</div>
                    <Button
                      label={`${callToActionBtn.short_title}${
                        item?._lang !== language &&
                        !entityConfig.ignore_different_lang
                          ? " (" + onlyEnglishBtn.label + ")"
                          : ""
                      }`}
                      locale={
                        item?._lang !== language &&
                        !entityConfig.ignore_different_lang
                          ? country + "_en"
                          : entityConfig.ignore_different_lang
                          ? country + "_" + language
                          : null
                      }
                      url={item._url}
                      variant={"small"}
                      blank={item._url?.includes("//")}
                    />
                  </div>
                );
              })}
            </Slider>
          )}
        </Container>
      );
    }

    if (layout === "preview") {
      const items = props.content_set?.[0]?.items;
      const highlighted = items.slice(0, 3) as WithResolvedUrl<Entity>[];
      const rest = items.slice(3, 6) as WithResolvedUrl<Entity>[];

      return (
        <Container
          edge={getEdgeSettings(props)}
          background={props.background_color}
          layout={props.header_layout}
          fluid={isFinder}
          marginTop={props.margin_top}
          marginBottom={props.margin_bottom}
          id={`component__content-set-${props.index}`}
          className={`${
            !isFinder ? "section " : "contentset-finder pt-3 "
          }component__content-set`}
          header={
            <HeadingWrapper
              {...props}
              alignment={isFinder ? "center" : null}
              border={!isFinder && props.header_layout !== "heading-left"}
              margin="mb-3"
            />
          }
        >
          <Row>
            {highlighted.map((highlight) => {
              return (
                <Col xl={3} md={12} className="mb-xl-0 mb-3" key={highlight.id}>
                  <Card
                    url={highlight._url}
                    externalUrl={highlight._url?.includes("//")}
                    variant="vertical"
                    // date={{ startDate: highlight.publishing_start_date }}
                    title={highlight.title}
                    html={
                      ("excerpt" in highlight && highlight?.excerpt) ||
                      undefined
                    }
                    actionButtons={[
                      {
                        variant: "white",
                        label: callToActionBtn.short_title,
                        url: highlight._url,
                        blank: highlight._url?.includes("//"),
                      },
                    ]}
                    {...("images" in highlight &&
                    (highlight.images as KeyVisual[]).length > 0
                      ? {
                          cardImg: {
                            media: highlight.images[0],
                            ar:
                              (preview_image_framing !== "none" &&
                                preview_image_framing) ||
                              "ar32",
                            background: preview_image_background || "none",
                            ...(preview_image_framing !== "none" &&
                              preview_image_framing_cl_param && {
                                overwriteNamedTransformation:
                                  preview_image_framing_cl_param,
                              }),
                            ...(preview_image_container_border && {
                              border: preview_image_container_border,
                            }),
                          },
                        }
                      : {})}
                  />
                </Col>
              );
            })}
            <Col xl={3} md={12} className="news-list">
              {rest.map((re, index) => {
                return (
                  <div key={`news-${index}`}>
                    {/* <div
                      style={{ color: "#808B94", fontSize: "14px" }}
                      className="mb-1"
                    >
                      {formatDate(re.publishing_start_date, locale)}
                    </div> */}
                    {re._url ? (
                      <Link
                        href={re._url ?? ""}
                        target={re._url?.includes("//") ? "_blank" : "_self"}
                        passHref
                        prefetch={false}
                        style={{ color: "#455C6E" }}
                        className="font-weight-bold glbl-hover"
                      >
                        {re.title}
                      </Link>
                    ) : (
                      <a
                        style={{ color: "#455C6E" }}
                        className="font-weight-bold glbl-hover"
                      >
                        {re.title}
                      </a>
                    )}
                    {index !== rest.length - 1 && <hr />}
                  </div>
                );
              })}
            </Col>
          </Row>
        </Container>
      );
    }

    const isFullscreenButton =
      layout === "list" &&
      props.content_set?.[0]?.contentset?.default_view === "fullscreen_button";

    return (
      <Container
        edge={getEdgeSettings(props)}
        background={props.background_color}
        layout={props.header_layout}
        fluid={isFinder || isFullscreenButton}
        marginTop={props.margin_top}
        marginBottom={props.margin_bottom}
        id={`component__content-set-${props.index}`}
        className={classNames(
          layout === "finder"
            ? "contentset-finder pt-3 "
            : isFullscreenButton
            ? "contentset-fullscreen "
            : "section ",
          "component__content-set",
        )}
        header={
          !isFullscreenButton && (
            <HeadingWrapper
              {...props}
              alignment={isFinder ? "center" : null}
              border={Boolean(
                layout !== "finder" && props.header_layout !== "heading-left",
              )}
            />
          )
        }
      >
        {isFinder ? (
          <QuickFilter quickfilter={props.content_set[0].quickfilter} />
        ) : (
          <ContentSet
            {...props.content_set[0]}
            base={props.base}
            headline={props.page.title}
          />
        )}
      </Container>
    );
  }

  return (
    <Container>
      <HeadingWrapper {...props} />
      <DefaultComponent {...props} />
    </Container>
  );
};

export default ContentSetWrapper;
