import { has, isArray } from "lodash";
import { FlatTreeItem } from "constants/types";

interface FlattenTreeProps<T> {
  childrenKey?: string;
  nodes: T[];
}

type Node = Record<string, unknown>;

export const flattenTree = <T = Node>({
  childrenKey = "children",
  nodes,
}: FlattenTreeProps<T>): FlatTreeItem<T>[] => {
  const flatTree: FlatTreeItem<T>[] = [];

  let id = 0;

  nodes.forEach((node) => {
    processNode(node);
  });

  function processNode(node: T, parent = null, depth = 0) {
    const { [childrenKey]: children, ...item } = node as Node;

    const hasChildren = children && isArray(children) && children.length > 0;

    const processedNode = {
      parent: parent,
      ...item,
      ...(has(item, "depth") ? {} : { depth }),
      ...(has(item, "id") ? {} : { id }),
      ...(has(item, "hasChildren") ? {} : { hasChildren }),
    };

    flatTree.push(processedNode as FlatTreeItem<T>);

    id++;

    if (hasChildren) {
      Object.values(children)?.forEach((child: T) => {
        processNode(child, processedNode.id, depth + 1);
      });
    }
  }

  return flatTree;
};
